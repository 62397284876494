:root {
  --ck-z-default: 500 !important;
  --ck-z-modal: calc( var(--ck-z-default) + 999 ) !important;
}

html, body {
  height: 100%;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

ul {
  padding: 0;
  margin: 0;
}

ul, ol {
  margin-block-start: 0px;
  margin-block-end: 0px;
}

img {
  max-width: 100%;
}

.slick-track {
  margin: 0;
}
.slick-list,
.slick-track {
  height: 100%;
}

.app-preview-slick {
  height: 100%;
}

.app-preview-slick .slick-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-preview-slick .slick-slide > div {
  height: 100%;
}

.app-preview-slick .slick-slide img {
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: auto!important;
}

.app-preview-slick .slick-dots {
  bottom: 10px;
}

.app-preview-slick .slick-dots li,
.app-preview-slick .slick-dots li button,
.app-preview-slick .slick-dots li button:before {
  width: 14px;
  height: 14px;
}

.app-preview-slick .slick-dots li button:before {
  line-height: 14px;
}

:focus {
  outline: none;
}

.ck-editor__editable_inline {
  min-height: 150px;
}

/* CK Content styles */
/*
 * CKEditor 5 (v15.0.0) content styles.
 * Generated on Wed, 27 Nov 2019 13:26:13 GMT.
 * For more information, check out https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/content-styles.html
 */

:root {
  --ck-highlight-marker-blue: #72cdfd;
  --ck-highlight-marker-green: #63f963;
  --ck-highlight-marker-pink: #fc7999;
  --ck-highlight-marker-yellow: #fdfd77;
  --ck-highlight-pen-green: #118800;
  --ck-highlight-pen-red: #e91313;
  --ck-image-style-spacing: 1.5em;
  --ck-todo-list-checkmark-size: 16px;
}

/* ckeditor5-list/theme/todolist.css */
.ck-editor ul,
.ck-content ul {
  padding-left: 40px;
}
.ck-editor p,
.ck-content p {
  margin-top: 0;
  margin-bottom: 4px;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image {
  display: table;
  clear: both;
  text-align: center;
  margin: 1em auto;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image > img {
  display: block;
  margin: 0;
  max-width: 100%;
  min-width: 50px;
}
/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content blockquote {
  overflow: hidden;
  padding-right: 1.5em;
  padding-left: 1.5em;
  margin-left: 0;
  margin-right: 0;
  font-style: italic;
  border-left: solid 5px hsl(0, 0%, 80%);
}
/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content[dir="rtl"] blockquote {
  border-left: 0;
  border-right: solid 5px hsl(0, 0%, 80%);
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized {
  max-width: 100%;
  display: block;
  box-sizing: border-box;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized img {
  width: 100%;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized > figcaption {
  display: block;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-side,
.ck-content .image-style-align-left,
.ck-content .image-style-align-center,
.ck-content .image-style-align-right {
  max-width: 50%;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-side {
  float: right;
  margin-left: var(--ck-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left,
.ck-content .image-style-align-right {
  margin: 0;
  float: none;
  display: flex;
  max-width: 100%;
}

.ck-content .image-style-align-left {
  justify-content: flex-start;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-center {
  margin-left: auto;
  margin-right: auto;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-right {
  justify-content: flex-end;
}
/* ckeditor5-media-embed/theme/mediaembed.css */
.ck-content .media {
  clear: both;
  margin: 1em 0;
  display: block;
  min-width: 15em;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table {
  margin: 1em auto;
  display: block;
  width: 100%;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table {
  max-width: 100%;
  display: block;
  border: 1px solid hsl(0, 0%, 85%);
  border-bottom: none;
  border-left: none;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table td,
.ck-content .table table th {
  flex: 1;
  min-width: 2em;
  padding: .4em;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table thead,
.ck-content .table table tbody {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}
.ck-content .table table th {
  display: block;
  font-weight: bold;
  background: hsl(0, 0%, 98%);
}
.ck-content .table table tr {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
.ck-content .table table th,
.ck-content .table table td {
  word-break: break-all;
  border: 1px solid hsl(0, 0%, 85%);
  border-top: none;
  border-right: none;
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-yellow {
  background-color: var(--ck-highlight-marker-yellow);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-green {
  background-color: var(--ck-highlight-marker-green);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-pink {
  background-color: var(--ck-highlight-marker-pink);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-blue {
  background-color: var(--ck-highlight-marker-blue);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-red {
  color: var(--ck-highlight-pen-red);
  background-color: transparent;
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-green {
  color: var(--ck-highlight-pen-green);
  background-color: transparent;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break {
  position: relative;
  clear: both;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break::after {
  content: '';
  position: absolute;
  border-bottom: 2px dashed hsl(0, 0%, 77%);
  width: 100%;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break__label {
  position: relative;
  z-index: 1;
  padding: .3em .6em;
  display: block;
  text-transform: uppercase;
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
  font-size: 0.75em;
  font-weight: bold;
  color: hsl(0, 0%, 20%);
  background: #fff;
  box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* ckeditor5-basic-styles/theme/code.css */
.ck-content code {
  background-color: hsla(0, 0%, 78%, 0.3);
  padding: .15em;
  border-radius: 2px;
}
/* ckeditor5-image/theme/imagecaption.css */
.ck-content .image > figcaption {
  display: table-caption;
  caption-side: bottom;
  word-break: break-word;
  color: hsl(0, 0%, 20%);
  background-color: hsl(0, 0%, 97%);
  padding: .6em;
  font-size: .75em;
  outline-offset: -1px;
}
/* ckeditor5-horizontal-line/theme/horizontalline.css */
.ck-content hr {
  border-width: 1px 0 0;
  border-style: solid;
  border-color: hsl(0, 0%, 37%);
  margin: 0;
}
/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre {
  padding: 1em;
  color: #353535;
  background: hsla(0, 0%, 78%, 0.3);
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  text-align: left;
  direction: ltr;
  tab-size: 4;
  white-space: pre-wrap;
  font-style: normal;
  min-width: 200px;
}
/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre code {
  background: unset;
  padding: 0;
  border-radius: 0;
}
@media print {
  /* ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break {
    padding: 0;
  }
  /* ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break::after {
    display: none;
  }
}

.ck.ck-dropdown .ck-dropdown__panel {
  max-height: 200px;
  overflow: scroll;
}
